import gql from 'graphql-tag';
import { FRAGMENT_SETTINGS_INFO } from '../';

export const EDIT_SETTINGS_ORDER_TIME = gql`
    mutation editSettingsOrderTime($name: String!, $value: String!) {
        editSettingsOrderTime(name: $name, value: $value) {
            ...SettingsInfo
        }
    }
    ${FRAGMENT_SETTINGS_INFO}
`;

export const EDIT_SETTINGS_TRAINEE_LUNCH_LIMIT = gql`
    mutation editSettingsTraineeLunchLimit($name: String!, $value: String!) {
        editSettingsTraineeLunchLimit(name: $name, value: $value) {
            ...SettingsInfo
        }
    }
    ${FRAGMENT_SETTINGS_INFO}
`;

export const EDIT_SETTINGS_IP = gql`
    mutation editSettingsIp($name: String!, $value: String!) {
        editSettingsIp(name: $name, value: $value) {
            ...SettingsInfo
        }
    }
    ${FRAGMENT_SETTINGS_INFO}
`;
