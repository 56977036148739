import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import { EDIT_SETTINGS_IP } from '../../../gql';
import { Modal } from '../../../components';

const PART_OF_IP_MASK_REGEX = /((\d{1,3})((\.\d{1,3})|(?<!\.)(\.)){0,3})|^$/;
const IP_MASK_REGEX = /(\d{1,3})(\.\d{1,3}){3}/;

const isPartOfIpMask = (text) => {
  const [matchedText = ''] = text.match(PART_OF_IP_MASK_REGEX) || [];

  return matchedText.length === text.length;
}; 

const MAX_IP_LENGTH = 'xxx.xxx.xxx.xxx'.length;
const MAX_IPS_FIELD_LENGTH = 255;
// 1 is ',' between ips. Ex.: '123.1.2.3,231.2.3.4'
// +1 for MAX_IPS_FIELD_LENGTH is needed because there is no ',' at the end
const MAX_IPS_COUNT = Math.floor((MAX_IPS_FIELD_LENGTH + 1) / (MAX_IP_LENGTH + 1));

const ORANGE_ZONE_FOR_IPS_FIELD = Math.ceil(MAX_IPS_COUNT * 0.7); // >70%

class AdminEditIpAddresses extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ips: props.ip.split(','),
      newIp: '',
    };
  }

  onSubmit = (editSettingsIp) => {
    const { ips } = this.state;

    editSettingsIp({
      variables: {
        name: 'ip',
        value: ips.join(','),
      },
    });
  };

  render() {
    const { onClose } = this.props;
    const { ips, newIp } = this.state;

    return (
      <Mutation mutation={EDIT_SETTINGS_IP} onCompleted={onClose}>
        {editSettingsIp => (
          <Modal
            active
            onClose={onClose}
            onSuccess={() => this.onSubmit(editSettingsIp)}
            header="Редактирование IP адреса с которого можно заказать обед или зарегистрироваться"
            bodyClass="content"
            body={
              <div className="container">
                <form className="ui left icon input right labeled" onSubmit={(e) => {
                      e.preventDefault();
                      this.setState({
                        ips: [...ips, newIp],
                        newIp: '',
                      });
                    }}>
                  <i className="laptop icon" />
                  <input
                    name="ip"
                    value={newIp}
                    onChange={({ target: { value } }) => {
                      if (isPartOfIpMask(value)) {
                        this.setState({ newIp: value })}
                      }
                    }
                  />
                  <button
                    className="ui button icon label"
                    disabled={ips.length === MAX_IPS_COUNT || !IP_MASK_REGEX.test(newIp) || ips.includes(newIp)}
                  >
                    <i className="add icon" />
                  </button>
                </form>
                <div>
                  <span
                    className={
                      `ui text ${
                        ips.length === MAX_IPS_COUNT
                          ? 'red'
                          : (ips.length >= ORANGE_ZONE_FOR_IPS_FIELD ? 'orange' : 'green')
                      }`
                    }>
                      {ips.length} / {MAX_IPS_COUNT}
                  </span>
                </div>
                <div className="ui list" style={{ width: "fit-content", display: 'flex', flexDirection: 'column-reverse' }}>
                  {ips.map((ip, i) => (
                    <div className={`ui item${ip === newIp ? ' red' : ''}`} style={ip === newIp ? { fontWeight: 'bold'} : undefined}>
                      <span style={{ width: 32, display: 'inline-block', background: 'default' }}>{i + 1}.</span>
                      <button
                        className="ui icon button right floated inverted"
                        style={{ padding: 0 }}
                        onClick={() => {
                          const newIps = [...ips];
                          newIps.splice(i, 1)
                          this.setState({ ips: newIps });
                        }}
                      >
                        <i className="times red icon" />
                      </button>
                      <span className="text">{ip}</span>
                    </div>
                  ))}
                </div>
              </div>
            }
            size="small"
          />
        )}
      </Mutation>
    );
  }
}

AdminEditIpAddresses.propTypes = {
  onClose: PropTypes.func,
  ip: PropTypes.string,
};

AdminEditIpAddresses.defaultProps = {
  ip: '',
  onClose: () => {},
};

export default AdminEditIpAddresses;
